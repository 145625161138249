// src/components/SignUp.js
import React, { useState, useContext } from 'react';
import { AuthContext } from '../AuthContext';
import '../styles/signup.css';

const SignUp = ({ setIsLogin }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const { signup, googleLogin } = useContext(AuthContext);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await signup(email, password);
    } catch (error) {
      setError('Failed to sign up: ' + error.message);
    }
  };

  const handleGoogleSignUp = async () => {
    try {
      await googleLogin();
    } catch (error) {
      setError('Failed to sign up with Google: ' + error.message);
    }
  };

  return (
    <div className="signup-container">
      <h2>Create Account</h2>
      {error && <div className="error">{error}</div>}
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="form-group">
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <button type="submit" className="submit-button">Sign Up</button>
      </form>

      <div className="divider">
        <span>or</span>
      </div>

      <button 
        onClick={handleGoogleSignUp} 
        className="google-login-button"
      >
        <img 
          src="https://www.google.com/favicon.ico" 
          alt="Google" 
          className="google-icon"
        />
        Sign up with Google
      </button>

      <div className="switch-form">
        <p>Already have an account? <button onClick={() => setIsLogin(true)}>Log In</button></p>
      </div>
    </div>
  );
};

export default SignUp;