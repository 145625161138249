// App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthProvider } from './AuthContext';
import LandingPage from './components/LandingPage';
import AppContent from './components/AppContent';
import UpgradePage from './components/UpgradePage';

function App() {
  return (
    <Router>
      <AuthProvider>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/auth" element={<AppContent />} />
          <Route path="/chat" element={<AppContent />} />
          <Route path="/upgrade" element={<UpgradePage />} />
        </Routes>
      </AuthProvider>
    </Router>
  );
}

export default App;