// src/components/UpgradePage.jsx
import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../AuthContext';
import { db } from '../firebase';
import { collection, addDoc } from 'firebase/firestore';
import '../styles/UpgradePage.css';

const UpgradePage = () => {
    const [formData, setFormData] = useState({
        name: '',
        phone: '',
        profession: ''
    });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const { user } = useContext(AuthContext);

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError('');

        try {
            // Save upgrade request to Firestore
            await addDoc(collection(db, 'upgradeRequests'), {
                userId: user.uid,
                email: user.email,
                name: formData.name,
                phone: formData.phone,
                profession: formData.profession,
                status: 'pending',
                timestamp: new Date(),
            });

            // Show success message and redirect
            alert('Upgrade request submitted successfully! We will contact you soon.');
            navigate('/chat');
        } catch (err) {
            console.error('Error submitting upgrade request:', err);
            setError('Failed to submit request. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="upgrade-page">
            <div className="upgrade-container">
                <h1>Upgrade to Pro</h1>
                <p className="subtitle">Get unlimited access to AI Assistant</p>

                <form onSubmit={handleSubmit} className="upgrade-form">
                    <div className="form-group">
                        <label htmlFor="name">Full Name</label>
                        <input
                            type="text"
                            id="name"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            required
                            placeholder="Enter your full name"
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="phone">Phone Number</label>
                        <input
                            type="tel"
                            id="phone"
                            name="phone"
                            value={formData.phone}
                            onChange={handleChange}
                            required
                            placeholder="Enter your phone number"
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="profession">Profession</label>
                        <input
                            type="text"
                            id="profession"
                            name="profession"
                            value={formData.profession}
                            onChange={handleChange}
                            required
                            placeholder="Enter your profession"
                        />
                    </div>

                    {error && <div className="error-message">{error}</div>}

                    <button 
                        type="submit" 
                        className="upgrade-submit-button"
                        disabled={loading}
                    >
                        {loading ? 'Processing...' : 'Submit Request'}
                    </button>
                </form>
            </div>
        </div>
    );
};

export default UpgradePage;