// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBpEmvbXi8ggfP0_D4A_1Gfhj8P0go1AUk",
  authDomain: "returnfilerbeta.firebaseapp.com",
  projectId: "returnfilerbeta",
  storageBucket: "returnfilerbeta.firebasestorage.app",
  messagingSenderId: "449376697591",
  appId: "1:449376697591:web:847b77c856deb77f02e7f2"
};

// Initialize Firebase
console.log("Initializing Firebase");

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);

console.log("Firebase initialized");