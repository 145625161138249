// src/components/Login.js
import React, { useState, useContext } from 'react';
import { AuthContext } from '../AuthContext';
import '../styles/login.css'; // New styles

const Login = ({ setIsLogin }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const { login, googleLogin } = useContext(AuthContext);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await login(email, password);
    } catch (error) {
      setError('Failed to log in: ' + error.message);
    }
  };

  const handleGoogleLogin = async () => {
    try {
      await googleLogin();
    } catch (error) {
      setError('Failed to log in with Google: ' + error.message);
    }
  };

  return (
    <div className="login-container">
      <h2>Login</h2>
      {error && <div className="error">{error}</div>}
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="form-group">
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <button type="submit" className="submit-button">Login</button>
      </form>

      <div className="divider">
        <span>or</span>
      </div>

      <button 
        onClick={handleGoogleLogin} 
        className="google-login-button"
      >
        <img 
          src="https://www.google.com/favicon.ico" 
          alt="Google" 
          className="google-icon"
        />
        Continue with Google
      </button>

      <div className="switch-form">
        <p>Not a user? <button onClick={() => setIsLogin(false)}>Sign Up</button></p>
      </div>
    </div>
  );
};

export default Login;