// src/components/AppContent.js
import React, { useContext, useState } from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import { AuthContext } from '../AuthContext';
import Login from './Login';
import SignUp from './SignUp';
import Assistant from './Assistant';

const AppContent = () => {
  const { user } = useContext(AuthContext);
  const [isLogin, setIsLogin] = useState(true);
  const location = useLocation();

  // If on /chat path and not logged in, redirect to auth
  if (location.pathname === '/chat' && !user) {
    return <Navigate to="/auth" replace />;
  }

  // If logged in and on /auth, redirect to chat
  if (user && location.pathname === '/auth') {
    return <Navigate to="/chat" replace />;
  }

  // If logged in and on /chat, show Assistant
  if (user && location.pathname === '/chat') {
    return <Assistant />;
  }

  // Show login/signup forms
  return isLogin ? (
    <Login setIsLogin={setIsLogin} />
  ) : (
    <SignUp setIsLogin={setIsLogin} />
  );
};

export default AppContent;