import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../AuthContext';

const LandingPage = () => {
  /* Your testimonials remain the same */
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const [isHovered, setIsHovered] = useState(false);

  const handleGetStarted = () => {
    if (user) {
      navigate('/chat');
    } else {
      navigate('/auth');
    }
  };
  
  const testimonials = [
    {
      name: 'John Doe',
      feedback:
        'Return Filer revolutionized our tax filing process. The AI chatbot is incredibly helpful, saving us hours every month!'
    },
    {
      name: 'Jane Smith',
      feedback:
        'We love how easy it is to visualize our finances on their dashboards. It has truly helped us make better decisions.'
    },
    {
      name: 'Sam Wilson',
      feedback:
        'The secure vault feature offers peace of mind. All our important files are stored in one place, safe and accessible.'
    }
  ];

  /* Track mobile vs. desktop, and whether mobile menu is open */
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
      if (window.innerWidth >= 768) {
        setMobileMenuOpen(false);
      }
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const styles = {
    /* GLOBAL CONTAINER */
    container: {
      margin: 0,
      padding: 0,
      fontFamily: "'Poppins', sans-serif",
      backgroundColor: '#ffffff',
      color: '#000000',
      minHeight: '100vh',
      display: 'flex',
      flexDirection: 'column'
    },

    /* NAVBAR */
    nav: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      backgroundColor: '#181818',
      padding: '1rem 2rem',
      position: 'relative' 
      // So we can position the dropdown absolutely inside the navbar
    },
    navLeft: {
      display: 'flex',
      alignItems: 'center',
      gap: '1rem',
      textDecoration: 'none'
    },
    navLogo: {
      width: '40px',
      height: '40px',
      objectFit: 'contain',
      borderRadius: '50%'
    },
    navBrand: {
      fontSize: '1.4rem',
      fontWeight: 600,
      margin: 0,
      color: '#ecf0f1'
    },

    navRight: {
      // Desktop styling fallback
      display: 'flex',
      alignItems: 'center',
      gap: '1.5rem',
    },

    /* Generic nav link style */
    navLink: {
      color: '#ecf0f1',
      textDecoration: 'none',
      fontWeight: 500,
      cursor: 'pointer',
      transition: 'color 0.3s ease'
    },
    navLinkHover: {
      color: '#3498db'
    },

    /* CONTACT US BUTTON (gradient) */
    navContactButton: {
      background: 'linear-gradient(45deg,rgb(64, 54, 157),rgb(80, 121, 163))',
      color: '#ffffff',
      textDecoration: 'none',
      fontWeight: 500,
      padding: '0.5rem 1rem',
      borderRadius: '4px',
      transition: 'transform 0.3s ease, filter 0.3s ease'
    },
    navContactButtonHover: {
      filter: 'brightness(1.1)',
      transform: 'translateY(-2px)'
    },

    /* HAMBURGER ICON */
    navToggle: {
      display: 'flex',
      flexDirection: 'column',
      gap: '5px',
      cursor: 'pointer'
    },
    hamburgerLine: {
      width: '25px',
      height: '3px',
      backgroundColor: '#ecf0f1'
    },

    /* STYLING FOR THE MOBILE MENU DROPDOWN */
    mobileMenuContainer: {
      position: 'absolute',
      top: '60px',      // just below the navbar
      right: '1rem',
      backgroundColor: '#333',
      borderRadius: '6px',
      padding: '1rem',
      display: 'flex',
      flexDirection: 'column',
      gap: '1rem',
      zIndex: 999,     // ensures it appears on top
    },

    /* HERO SECTION */
    heroSection: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '5rem 1rem',
      textAlign: 'center',
      background: `linear-gradient(
        rgba(0, 0, 0, 0.7),
        rgba(0, 0, 0, 0.7)
      ), url('/bg_hero.png') center/cover no-repeat`,
      color: '#fff'
    },
    heroTitle: {
      fontSize: '3rem',
      margin: '0 0 1rem 0',
      fontWeight: 600
    },
    heroSubtitle: {
      fontSize: '1.15rem',
      marginBottom: '2rem',
      fontWeight: 300,
      maxWidth: '600px'
    },
    getStartedButton: {
      backgroundColor: '#3498db',
      color: '#ffffff',
      padding: '0.75rem 2rem',
      fontSize: '1rem',
      border: 'none',
      borderRadius: '4px',
      cursor: 'pointer',
      transition: 'background-color 0.3s ease, transform 0.3s ease'
    },
    getStartedButtonHover: {
      backgroundColor: '#2980b9',
      transform: 'translateY(-2px)'
    },

    /* FEATURES SECTION */
    featuresSection: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '4rem 1rem',
      background: 'linear-gradient(to right,rgb(22, 23, 23),rgb(25, 26, 26))'
    },
    featuresTitle: {
      fontSize: '2rem',
      marginBottom: '2rem',
      color: '#fff',
      fontWeight: 600
    },
    featuresContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: '3rem',
      maxWidth: '1100px',
      width: '100%'
    },

    /* AI Chatbot row */
    aiChatbotRow: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: '2rem',
      backgroundColor: '#ffffff',
      borderRadius: '8px'
    },
    aiImageContainer: {
      flex: '1 1 400px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    aiImage: {
      maxWidth: '100%',
      borderRadius: '6px',
      boxShadow: '0px 1px 4px rgba(0,0,0,0.1)'
    },
    aiTextContainer: {
      flex: '1 1 400px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      padding: '1rem'
    },
    aiTitle: {
      fontSize: '1.8rem',
      margin: '0 0 1rem 0',
      color: '#333',
      fontWeight: 500
    },
    aiDescription: {
      fontSize: '1rem',
      color: '#666',
      lineHeight: '1.6',
      marginBottom: '1rem'
    },
    aiList: {
      marginLeft: '1.25rem'
    },
    aiListItem: {
      marginBottom: '0.5rem'
    },

    /* "Coming soon" placeholders in features */
    upcomingFeaturesRow: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: '2rem',
      justifyContent: 'center'
    },
    upcomingFeatureCard: {
      flex: '1 1 300px',
      background: 'linear-gradient(135deg, #e8f3ff, #f6fbff)',
      border: '1px solid #ddd',
      borderRadius: '6px',
      padding: '1.5rem',
      textAlign: 'center',
      boxShadow: '0px 1px 4px rgba(0,0,0,0.1)',
      transition: 'box-shadow 0.3s ease, transform 0.3s ease'
    },
    upcomingFeatureTitle: {
      fontSize: '1.4rem',
      margin: '0 0 1rem 0',
      color: '#333',
      fontWeight: 500
    },
    upcomingFeatureText: {
      fontSize: '0.95rem',
      color: '#666'
    },
    upcomingFeatureHover: {
      boxShadow: '0px 4px 10px rgba(0,0,0,0.2)',
      transform: 'translateY(-4px)'
    },

    /* COMING SOON SECTION (Video Integration) */
    comingSoonSection: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '4rem 1rem',
      background: 'linear-gradient(to right,rgb(22, 23, 23),rgb(56, 58, 59))'
    },
    comingSoonTitle: {
      fontSize: '2rem',
      marginBottom: '2rem',
      color: '#fff',
      fontWeight: 600
    },
    comingSoonFeatures: {
      display: 'flex',
      flexDirection: 'column',
      gap: '3rem',
      maxWidth: '700px',
      width: '100%'
    },
    comingSoonItem: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center'
    },
    videoContainer: {
      width: '100%',
      maxWidth: '800px',
      position: 'relative',
      paddingBottom: '56.25%',
      height: 0,
      margin: '0 auto'
    },
    video: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      pointerEvents: 'none'
    },
    comingSoonItemTitle: {
      fontSize: '1.5rem',
      color: '#fff',
      marginBottom: '1rem',
      fontWeight: 500,
      background: 'linear-gradient(135deg,rgb(158, 149, 158),rgb(18, 18, 19))',
      display: 'inline-block',
      padding: '0.5rem 1rem',
      borderRadius: '4px',
      boxShadow: '0 2px 6px rgba(0,0,0,0.25)',
      letterSpacing: '0.5px'
    },

    /* TESTIMONIALS SECTION */
    testimonialsSection: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '4rem 1rem',
      background: 'linear-gradient(to right,rgb(46, 49, 51),rgb(21, 6, 6))'
    },
    testimonialsTitle: {
      fontSize: '2rem',
      marginBottom: '2rem',
      color: '#fff',
      fontWeight: 600
    },
    testimonialsContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: '2rem',
      justifyContent: 'center',
      maxWidth: '1100px',
      width: '100%'
    },
    testimonialCard: {
      backgroundColor: '#fff',
      border: '1px solid #ddd',
      borderRadius: '6px',
      width: '300px',
      padding: '1.5rem',
      boxShadow: '0px 1px 4px rgba(0,0,0,0.1)',
      transition: 'box-shadow 0.3s ease, transform 0.3s ease'
    },
    testimonialCardHover: {
      boxShadow: '0px 4px 10px rgba(0,0,0,0.2)',
      transform: 'translateY(-4px)'
    },
    testimonialName: {
      fontSize: '1.1rem',
      fontWeight: 600,
      margin: '0 0 0.5rem 0',
      color: '#333'
    },
    testimonialFeedback: {
      fontSize: '0.95rem',
      color: '#666',
      lineHeight: '1.5'
    },

    /* FOOTER */
    footer: {
      background: 'linear-gradient(to right, #2c3e50, #3b4b60)',
      color: '#ecf0f1',
      textAlign: 'center',
      padding: '1rem',
      marginTop: 'auto'
    }
  };

/* HOVER HANDLERS */
const handleNavLinkEnter = (e) => {
  e.currentTarget.style.color = styles.navLinkHover.color || '#3498db';
};

const handleNavLinkLeave = (e) => {
  e.currentTarget.style.color = styles.navLink.color;
};

const handleContactLinkEnter = (e) => {
  e.currentTarget.style.filter = styles.navContactButtonHover.filter;
  e.currentTarget.style.transform = styles.navContactButtonHover.transform;
};

const handleContactLinkLeave = (e) => {
  e.currentTarget.style.filter = 'none';
  e.currentTarget.style.transform = 'none';
};

const handleUpcomingFeatureEnter = (e) => {
  e.currentTarget.style.boxShadow = styles.upcomingFeatureHover.boxShadow;
  e.currentTarget.style.transform = styles.upcomingFeatureHover.transform;
};

const handleUpcomingFeatureLeave = (e) => {
  e.currentTarget.style.boxShadow = '0px 1px 4px rgba(0,0,0,0.1)';
  e.currentTarget.style.transform = 'none';
};

const handleTestimonialMouseEnter = (e) => {
  e.currentTarget.style.boxShadow = styles.testimonialCardHover.boxShadow;
  e.currentTarget.style.transform = styles.testimonialCardHover.transform;
};

const handleTestimonialMouseLeave = (e) => {
  e.currentTarget.style.boxShadow = styles.testimonialCard.boxShadow;
  e.currentTarget.style.transform = 'none';
};

// Combined get started button handlers
const handleGetStartedHover = (isEntering) => {
  setIsHovered(isEntering);
};

const getStartedButtonStyle = {
  ...styles.getStartedButton,
  ...(isHovered && styles.getStartedButtonHover)
};

  /*
    NEW: We'll handle the "Contact Us" link hovering similarly to how we do "Get Started".
    Because we have separate gradient style for the contact button.
  */

  return (
    <div style={styles.container}>
      {/* NAVBAR */}
      <nav style={styles.nav}>
        {/* LEFT SIDE: Logo + Brand */}
        <div style={styles.navLeft}>
          <img src="/rflogo.jpg" alt="Return Filer Logo" style={styles.navLogo} />
          <h2 style={styles.navBrand}>Return Filer</h2>
        </div>

        {/* HAMBURGER ICON (mobile only) */}
        {isMobile && (
          <div
            style={styles.navToggle}
            onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
          >
            <div style={styles.hamburgerLine}></div>
            <div style={styles.hamburgerLine}></div>
            <div style={styles.hamburgerLine}></div>
          </div>
        )}

        {/* NAV LINKS */}
        {/* If desktop, show inline. If mobile & open, show dropdown box. If mobile & closed, hide. */}
        {!isMobile ? (
          // DESKTOP MENU
          <div style={styles.navRight}>
            <a
              href="#features"
              style={styles.navLink}
              onMouseEnter={handleNavLinkEnter}
              onMouseLeave={handleNavLinkLeave}
            >
              Features
            </a>
            <a
              href="#coming-soon"
              style={styles.navLink}
              onMouseEnter={handleNavLinkEnter}
              onMouseLeave={handleNavLinkLeave}
            >
              Coming Soon
            </a>
            <a
              href="#testimonials"
              style={styles.navLink}
              onMouseEnter={handleNavLinkEnter}
              onMouseLeave={handleNavLinkLeave}
            >
              Testimonials
            </a>
            {/* GRADIENT CONTACT BUTTON: redirect to another site (example) */}
            <a
              href="https://returnfiler.in"
              target="_blank"
              rel="noopener noreferrer"
              style={styles.navContactButton}
              onMouseEnter={handleContactLinkEnter}
              onMouseLeave={handleContactLinkLeave}
            >
              For Enterprises
            </a>
          </div>
        ) : mobileMenuOpen ? (
          // MOBILE MENU DROPDOWN
          <div style={styles.mobileMenuContainer}>
            <a
              href="#features"
              style={styles.navLink}
              onMouseEnter={handleNavLinkEnter}
              onMouseLeave={handleNavLinkLeave}
              onClick={() => setMobileMenuOpen(false)}
            >
              Features
            </a>
            <a
              href="#coming-soon"
              style={styles.navLink}
              onMouseEnter={handleNavLinkEnter}
              onMouseLeave={handleNavLinkLeave}
              onClick={() => setMobileMenuOpen(false)}
            >
              Coming Soon
            </a>
            <a
              href="#testimonials"
              style={styles.navLink}
              onMouseEnter={handleNavLinkEnter}
              onMouseLeave={handleNavLinkLeave}
              onClick={() => setMobileMenuOpen(false)}
            >
              Testimonials
            </a>
            {/* Same gradient button in mobile menu */}
            <a
              href="https://returnfiler.in"
              target="_blank"
              rel="noopener noreferrer"
              style={styles.navContactButton}
              onMouseEnter={handleContactLinkEnter}
              onMouseLeave={handleContactLinkLeave}
              onClick={() => setMobileMenuOpen(false)}
            >
              For Enterprises
            </a>
          </div>
        ) : null}
      </nav>

      {/* HERO SECTION */}
      <section style={styles.heroSection}>
        <h1 style={styles.heroTitle}>Welcome to Return Filer</h1>
        <p style={styles.heroSubtitle}>
          Your trusted CA &amp; CS partner for taxation and more
        </p>
        <button
  style={getStartedButtonStyle}
  onMouseEnter={() => handleGetStartedHover(true)}
  onMouseLeave={() => handleGetStartedHover(false)}
  onClick={handleGetStarted}
>
  {user ? 'Go to Chat' : 'Get Started'}
</button>
      </section>

      {/* FEATURES SECTION */}
      <section style={styles.featuresSection} id="features">
        <h2 style={styles.featuresTitle}>Our Key Feature &amp; Future Plans</h2>

        <div style={styles.featuresContainer}>
          {/* Detailed AI Chatbot */}
          <div style={styles.aiChatbotRow}>
            <div style={styles.aiImageContainer}>
              <img
                style={styles.aiImage}
                src="/assitant.png"
                alt="AI Chatbot illustration"
              />
            </div>
            <div style={styles.aiTextContainer}>
              <h3 style={styles.aiTitle}>AI Chatbot for CA &amp; CS</h3>
              <p style={styles.aiDescription}>
                Our cutting-edge <strong>AI Chatbot</strong> is designed to answer all your
                corporate and tax-related queries with precision. Trained on CA &amp; CS
                expertise, it offers:
              </p>
              <ul style={styles.aiList}>
                <li style={styles.aiListItem}>24/7 instant support for tax filing questions</li>
                <li style={styles.aiListItem}>Automated guidance on complex corporate compliance</li>
                <li style={styles.aiListItem}>
                  Personalized recommendations based on your business structure
                </li>
              </ul>
              <p style={styles.aiDescription}>
                Whether you're a startup or an established firm, our AI Chatbot streamlines
                your financial and corporate workflows, saving you valuable time and effort.
              </p>
            </div>
          </div>

          {/* Other features as COMING SOON placeholders */}
          <div style={styles.upcomingFeaturesRow}>
            {/* Dashboard Visualization */}
            <div
              style={styles.upcomingFeatureCard}
              onMouseEnter={handleUpcomingFeatureEnter}
              onMouseLeave={handleUpcomingFeatureLeave}
            >
              <h4 style={styles.upcomingFeatureTitle}>Dashboard Visualization</h4>
              <p style={styles.upcomingFeatureText}>Coming Soon!</p>
            </div>

            {/* Secure Document Vault */}
            <div
              style={styles.upcomingFeatureCard}
              onMouseEnter={handleUpcomingFeatureEnter}
              onMouseLeave={handleUpcomingFeatureLeave}
            >
              <h4 style={styles.upcomingFeatureTitle}>Audit Safebox</h4>
              <p style={styles.upcomingFeatureText}>Coming Soon!</p>
            </div>
          </div>
        </div>
      </section>

      {/* COMING SOON SECTION (Video Integration) */}
      <section style={styles.comingSoonSection} id="coming-soon">
        <h2 style={styles.comingSoonTitle}>Coming Soon</h2>
        <div style={styles.comingSoonFeatures}>
          {/* Data Dashboard Video */}
          <div style={styles.comingSoonItem}>
            <h3 style={styles.comingSoonItemTitle}>Data Dashboard</h3>
            <div style={styles.videoContainer}>
              <video autoPlay loop muted playsInline style={styles.video}>
                <source src="/videos/dashboard.mp4" type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>

          {/* Vault Feature Video */}
          <div style={styles.comingSoonItem}>
            <h3 style={styles.comingSoonItemTitle}>Audit Safebox Feature</h3>
            <div style={styles.videoContainer}>
              <video autoPlay loop muted playsInline style={styles.video}>
                <source src="/videos/digisafe.mp4" type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
        </div>
      </section>

      {/* TESTIMONIALS SECTION */}
      <section style={styles.testimonialsSection} id="testimonials">
        <h2 style={styles.testimonialsTitle}>What Our Clients Say</h2>
        <div style={styles.testimonialsContainer}>
          {testimonials.map((testimonial, index) => (
            <div
              key={index}
              style={styles.testimonialCard}
              onMouseEnter={handleTestimonialMouseEnter}
              onMouseLeave={handleTestimonialMouseLeave}
            >
              <h4 style={styles.testimonialName}>{testimonial.name}</h4>
              <p style={styles.testimonialFeedback}>{testimonial.feedback}</p>
            </div>
          ))}
        </div>
      </section>

      {/* FOOTER */}
      <footer style={styles.footer} id="contact">
        © {new Date().getFullYear()} Return Filer. All rights reserved.
      </footer>
    </div>
  );
};

export default LandingPage;
